import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

import Component from './DrawioComponent.vue'

export default Node.create({
    name: 'drawioComponent',
    group: 'block',
    atom: true,

    addAttributes() {
        return {
            url: {
                default: '',
            },
            id: {
                default: '',
            },
            styles:{
                default: {
                    imageWidth:200,
                    imageHeight:200,
                    imgJustifyContent:'center',
                },
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'vue-component',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['vue-component', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },
    addCommands() {
        return {
            // 添加命令来插入自定义节点
            insertdrawioComponent: (options) => ({ commands }) => {
                return commands.insertContent({
                    type: this.type.name, // 指向定义的节点类型
                    attrs: {
                        url: options.url,
                        id: options.id,
                        styles:{
                            imageWidth:options.styles.imageWidth,
                            imageHeight:options.styles.imageHeight,
                            imgJustifyContent:options.styles.imgJustifyContent,
                        }
                    },
                })
            },
        }
    },
})