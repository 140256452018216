<template>
  <node-view-wrapper class="code-block">
    <div >
      <el-select
        size="mini"
        class="select-code-lan-zdh"
        v-model="selectedLanguage"
        filterable
        popper-class="select-code-lan-zdh-pover"
      >
        <el-option
          v-for="(language, index) in languages"
          :key="index"
          :label="language"
          :value="language"
        >
        </el-option>
      </el-select>
      <div
        class="code-block-copy-btn"
        @click="copy"
      >
        复制
      </div>
      <pre><code><node-view-content  /></code></pre>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,

  data() {
    return {
      show: false,
      languages: this.extension.options.lowlight.listLanguages(),
    };
  },

  computed: {
    selectedLanguage: {
      get() {
        return this.node.attrs.language;
      },
      set(language) {
        this.updateAttributes({ language });
      },
    },
  },
  methods: {
      handleVisibleChange(visible) {
      this.show = visible;
    },
    copy() {
      if (!this.node.content.content[0]) {
        return
      }
      let key = this.node.content.content[0].text;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(key);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = key;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
    },
  },
};
</script>

<style lang="scss">
.tiptap {
  .code-block {
    position: relative;
    .code-block-copy-btn {
      color: white;
      position: absolute;
      font-size: 12px;
      right: 20px;
      cursor: pointer;
      top: 0.5em;
    }
    .select-code-lan-zdh {
      position: absolute;
      left: 20px;
      top: 0.5em;
      input {
        background: none;
        color: white;
        border: none ;
        border-bottom: 1px white solid;
        border-radius: 0;
      }
    }
  }
}
.select-code-lan-zdh-pover {
  // background: red;
  .el-select-dropdown__item {
    font-size: 12px;
    height: 25px;
    line-height: 25px;
  }
  .el-select-dropdown__item:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
