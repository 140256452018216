import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js'
import { get_tenant_user_list } from "@/network/user/index.js";

import MentionList from './MentionList.vue'
import pinyin from "pinyin";

function filterUsersByNickname(searchStr) {
    let userList = JSON.parse(localStorage.getItem('zdh_tiptap_userlist')) 

    if (!searchStr) return userList; // 如果没有搜索关键词，返回原列表

    return userList.filter(user => {
        const nickname = user.nickname || "";

        // 直接匹配字符串
        if (nickname.includes(searchStr)) {
            return true;
        }

        // 转换 nickname 为拼音（不带声调）
        const nicknamePinyin = pinyin(nickname, { style: pinyin.STYLE_NORMAL }).flat().join("");

        // 检查是否匹配拼音
        return nicknamePinyin.includes(searchStr.toLowerCase());
    });

}
export default {
    items: ({ query }) => {
        console.log(filterUsersByNickname(query.toLowerCase()), 'ffff');

        return filterUsersByNickname(query.toLowerCase())
    },

    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new VueRenderer(MentionList, {
                    parent: this,
                    propsData: props,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}