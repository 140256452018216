<template>
  <div class="dropdown-menu">
    <button
      :class="{ 'is-selected': index === selectedIndex }"
      v-for="(item, index) in this.items"
      :key="index"
      @click="selectItem(index)"
    >
      {{ item.nickname }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },
  mounted() {
    console.log(564567, this.items);
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === "ArrowUp") {
        this.upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        this.downHandler();
        return true;
      }

      if (event.key === "Enter") {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items[index];

      if (item) {
        this.command({ id: item.nickname });
        // this.command({ accountId: item.accountId });
      }
    },
  },
};
</script>

<style lang="scss">
/* Dropdown menu */
.dropdown-menu {
    z-index: 9999999;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(45, 45, 46, 0.15) 0px 8px 16px -4px,
    rgba(45, 45, 46, 0.2) 0px 0px 1px 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  padding: 5px;
  position: relative;

  button {
    display: flex;
    justify-content: left;
    align-items: center;
    border:none;
    background-color: transparent;
    display: flex;
    gap: 5px;
  border-radius: 4px;
    text-align: left;
    width: 100%;
    padding: 3px 5px;
    cursor: pointer;

    &:hover,
    &:hover.is-selected {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.is-selected {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
