<template>
  <node-view-wrapper
    class="zdh-drawio-extension-tiptap"
    :style="{
      display: 'flex',
      justifyContent: imgJustifyContent,
    }"
  >
    <div
      class="image-resize-wrapper"
      :style="{
        width: imageWidth + 'px',
        height: imageHeight + 'px',
      }"
    >
      <!-- 图片 -->
      <div
        class="img_wrap"
        :style="{
          width: imageWidth + 40 + 'px',
          height: imageHeight + 40 + 'px',
        }"
        @mouseenter="show_menu_fun"
        @mouseleave="savechange"
      >
        <img
          :id="node.attrs.id"
          :src="drawiodata"
          alt=""
          class="drawio-extension-tiptap image-element"
          :style="{
            width: imageWidth + 'px',
            height: imageHeight + 'px',
            border: show_menu ? '2px solid #0064ff' : 'none',
            cursor: 'pointer',
          }"
          @click="zoom"
          @dblclick="edit_draw"
        />
        <div v-show="show_menu">
          <div class="posin-resize-zdh-tiptap top-zdh">
            <el-tooltip content="左对齐" placement="top">
              <span
                style="display: flex; align-items: center"
                @click="imgJustifyContent = 'flex-start'"
              >
                <i class="iconfont icon-zuoduiqi"> </i>
              </span>
            </el-tooltip>
            <el-divider direction="vertical"></el-divider>

            <el-tooltip content="居中对齐" placement="top">
              <span
                style="display: flex; align-items: center"
                @click="imgJustifyContent = 'center'"
              >
                <i class="iconfont icon-juzhongduiqi"> </i>
              </span>
            </el-tooltip>
            <el-divider direction="vertical"></el-divider>

            <el-tooltip content="右对齐" placement="top">
              <span
                style="display: flex; align-items: center"
                @click="imgJustifyContent = 'flex-end'"
              >
                <i class="iconfont icon-youduiqi"> </i>
              </span>
            </el-tooltip>
          </div>
          <div
            class="resize-handle top-left"
            @mousedown="handleMouseDown($event, 'top-left')"
          ></div>
          <div
            class="resize-handle top"
            @mousedown="handleMouseDown($event, 'top')"
          ></div>
          <div
            class="resize-handle top-right"
            @mousedown="handleMouseDown($event, 'top-right')"
          ></div>
          <div
            class="resize-handle right"
            @mousedown="handleMouseDown($event, 'right')"
          ></div>
          <div
            class="resize-handle bottom-right"
            @mousedown="handleMouseDown($event, 'bottom-right')"
          ></div>
          <div
            class="resize-handle bottom"
            @mousedown="handleMouseDown($event, 'bottom')"
          ></div>
          <div
            class="resize-handle bottom-left"
            @mousedown="handleMouseDown($event, 'bottom-left')"
          ></div>
          <div
            class="resize-handle left"
            @mousedown="handleMouseDown($event, 'left')"
          ></div>
        </div>
      </div>
    </div>
    <el-img-viewer
      class="viewer"
      :z-index="100000"
      preview-teleported
      v-if="visiblelist"
      :url-list="[itemdata]"
      :on-close="closeImgViewer"
    ></el-img-viewer>
    <!-- 添加 8 个紧贴图片的控制点 -->
  </node-view-wrapper>
</template>
<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import { Drawio } from "@/plugins/drawio.js";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
import {
  upload_drawio,
  download_drawio,
} from "@/network/fileOperation/index.js";
export default {
  components: {
    NodeViewWrapper,
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    nodeViewProps,
  },
  data() {
    return {
      clickTimeout: null,
      itemdata: null,
      visiblelist: false,
      drawiodata: "",
      imgJustifyContent: "center",
      show_menu: false,
      imageWidth: 200,
      imageHeight: 200,
      isResizing: false,
      currentHandle: null,
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
    };
  },
  mounted() {
    this.drawiodata = this.node.attrs.url;
    this.imgJustifyContent = this.node.attrs.styles.imgJustifyContent;
    this.imageHeight = this.node.attrs.styles.imageHeight;
    this.imageWidth = this.node.attrs.styles.imageWidth;

    ossAuthorization.call(this);
    window.addEventListener("message", this.handleMessage);
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("mouseup", this.handleMouseUp);
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    closeImgViewer() {
      this.visiblelist = false;
    },
    zoom() {
      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
        return;
      }
      this.clickTimeout = setTimeout(() => {
        this.clickTimeout = null;
        this.visiblelist = true;
        this.itemdata = this.drawiodata;
      }, 300); // 300ms 内如果发生 dblclick，则不触发 click
    },
    edit_draw() {
      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
      }
      this.edit_drawio();
    },
    get_base64(url) {
      if (url) {
        download_drawio(url).then((res) => {
          const reader = new FileReader();
          reader.readAsText(res);
          reader.onload = function (e) {
            return e.target.result;
          };
        });
      }
    },
    show_menu_fun() {
      if (this.editor.isEditable) {
        this.show_menu = true;
      }
    },
    savechange() {
      if (this.editor.isEditable) {
        this.show_menu = false;
        this.updateAttributes({
          styles: {
            imageWidth: this.imageWidth,
            imageHeight: this.imageHeight,
            imgJustifyContent: this.imgJustifyContent,
          },
        });
      }
    },
    handleMessage(e) {
      try {
        if (typeof e.data === "string") {
          let msg = JSON.parse(e.data).message.originelement;

          if (this.node.attrs.id != msg) {
            return
          }
          console.log(msg);
          
          let eventData = JSON.parse(e.data);
          let event = eventData.event;
          if (event === "export" || event === "save") {
            if (eventData.data) {
              this.drawiodata = eventData.data;
              this.updateAttributes({ url: eventData.data });
            }
            // this.save_draw(eventData);
          }
        }
      } catch (error) {}
    },
    save_draw(event) {
      let msg = event.message.originelement;
      let data = event.data;
      function dataURLtoFile(dataurl, filename) {
        if (!dataurl) return;
        let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }

      if (msg === this.node.attrs.id) {
        const file = dataURLtoFile(data, ulid());
        this.client
          .put(`/comment/${new Date().getTime()}${file.name}`, file)
          .then((res) => {
            this.node.attrs.url = res.url;
            this.updateAttributes({ url: res.url });
          });
      }
    },
    edit_drawio() {
      if (this.editor.isEditable) {
        document.querySelectorAll("#" + this.node.attrs.id).forEach((item) => {
          Drawio.editElement(item);
        });
      }
    },
    handleMouseDown(event, handle) {
      event.preventDefault();
      this.isResizing = true;
      this.currentHandle = handle;
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.startWidth = this.imageWidth;
      this.startHeight = this.imageHeight;
    },
    handleMouseMove(event) {
      if (!this.isResizing) return;
      const dx = event.clientX - this.startX;
      const dy = event.clientY - this.startY;
      switch (this.currentHandle) {
        case "right":
          this.imageWidth = this.startWidth + dx;
          break;
        case "left":
          this.imageWidth = this.startWidth - dx;
          break;
        case "bottom":
          this.imageHeight = this.startHeight + dy;
          break;
        case "top":
          this.imageHeight = this.startHeight - dy;
          break;
        case "top-left":
          this.imageWidth = this.startWidth - dx;
          this.imageHeight = this.startHeight - dy;
          break;
        case "top-right":
          this.imageWidth = this.startWidth + dx;
          this.imageHeight = this.startHeight - dy;
          break;
        case "bottom-left":
          this.imageWidth = this.startWidth - dx;
          this.imageHeight = this.startHeight + dy;
          break;
        case "bottom-right":
          this.imageWidth = this.startWidth + dx;
          this.imageHeight = this.startHeight + dy;
          break;
      }
      if (this.imageWidth < 50) this.imageWidth = 50;
      if (this.imageHeight < 50) this.imageHeight = 50;
    },
    handleMouseUp() {
      if (this.isResizing) {
        this.isResizing = false;
        this.updateAttributes({
          styles: {
            imageWidth: this.imageWidth,
            imageHeight: this.imageHeight,
            imgJustifyContent: this.imgJustifyContent,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.zdh-drawio-extension-tiptap {
  .image-resize-wrapper {
    position: relative;
    display: inline-block;
  }

  .image-element {
    display: block;
  }
  .posin-resize-zdh-tiptap {
    display: flex;
    position: absolute;
    background: #fff;
    border: 2px solid #0064ff;
    box-sizing: border-box;
    z-index: 10;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 15px;
    gap: 5px;
  }
  .top-zdh {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    //   cursor: ns-resize;
  }
  /* 控制点样式 */
  .resize-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    border: 2px solid #0064ff;
    box-sizing: border-box;
    z-index: 10;
    border-radius: 50%;
  }

  /* 四个角 */
  .top-left {
    top: -4px;
    left: -4px;
    cursor: nwse-resize;
  }
  .top-right {
    top: -4px;
    right: -4px;
    cursor: nesw-resize;
  }
  .bottom-right {
    bottom: -4px;
    right: -4px;
    cursor: nwse-resize;
  }
  .bottom-left {
    bottom: -4px;
    left: -4px;
    cursor: nesw-resize;
  }

  /* 上下左右边中间 */
  .top {
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize;
  }
  .bottom {
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize;
  }
  .left {
    left: -4px;
    top: 50%;
    transform: translateY(-50%);
    cursor: ew-resize;
  }
  .right {
    right: -4px;
    top: 50%;
    transform: translateY(-50%);
    cursor: ew-resize;
  }
}
</style>
