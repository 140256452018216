<template>
  <div class="editor_container">
    <div class="topbar">
      <span>名{{ click_type }}</span>
      <button @click="export_json">json</button>
      <div v-if="show_co_status" class="right-group">
        <el-popover
          v-show="co_list.length > 0"
          placement="bottom-end"
          trigger="click"
        >
          <div class="co-p">{{ co_list.length }}人正在查看</div>
          <div v-for="item in co_list" :key="item.accountId" class="co-li">
            <personal-avatar
              class="co-avatar"
              :size="20"
              :avatar="item.avatar"
              :nickname="item.nickname"
              :colorid="item.accountId"
            ></personal-avatar>
            <span> {{ item.nickname }}</span>
          </div>
          <div slot="reference" class="co-list">
            <personal-avatar
              v-for="item in co_list"
              :key="item.accountId"
              class="co-avatar"
              :size="20"
              :avatar="item.avatar"
              :nickname="item.nickname"
              :colorid="item.accountId"
            ></personal-avatar>
            <i class="el-icon-caret-bottom" style="font-size: 12px"></i>
          </div>
        </el-popover>
        <span v-show="co_list.length > 0">
          <el-divider direction="vertical"></el-divider>
        </span>
        <span
          style="
            color: rgb(115, 115, 115);
            font-size: 12px;
            display: flex;
            align-items: center;
          "
        >
          <div class="co-dot"></div>
          {{ co_status }}</span
        >

        <personal-avatar
          :size="25"
          :avatar="userInfo.avatar"
          :nickname="userInfo.userName"
          :colorid="userInfo.user_id"
          placement="right"
        ></personal-avatar>
        <el-divider direction="vertical" style="height: auto"></el-divider>
        <el-button size="mini">返回</el-button>
        <el-button
          v-if="!isEditable"
          size="mini"
          type="primary"
          @click="isEditable = true"
          >编辑</el-button
        >
        <el-button v-else size="mini" type="primary" @click="isEditable = false"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="false" class="toolbar">
      <el-dropdown
        split-button
        type="primary"
        @click="set_heading(0)"
        @command="set_heading"
      >
        正文
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1"><h1>一级标题</h1></el-dropdown-item>
          <el-dropdown-item command="2">2</el-dropdown-item>
          <el-dropdown-item command="3">3</el-dropdown-item>
          <el-dropdown-item command="4">4</el-dropdown-item>
          <el-dropdown-item command="5">5</el-dropdown-item>
          <el-dropdown-item command="6">6</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="tool_btn" @click="mark_b">
        <i class="iconfont icon-jiacu"> </i>
      </div>
      <i class="iconfont icon-xieti" @click="mark_i"> </i>
      <i class="iconfont icon-xiahuaxian" @click="mark_u"> </i>
      <i class="iconfont icon-shanchuxian" @click="mark_s"> </i>
      <i class="iconfont icon-shangbiao" @click="mark_up"> </i>
      <i class="iconfont icon-xiabiao" @click="mark_down"> </i>

      <el-color-picker
        v-model="font_color"
        show-alpha
        :predefine="predefineColors"
        @change="mark_font_color"
      >
      </el-color-picker>

      <el-color-picker
        v-model="background_color"
        color-format="hex"
        @change="mark_background_color"
      >
      </el-color-picker>

      <el-button-group>
        <el-button type="primary" @click="set_align('left')">左</el-button>
        <el-button type="primary" @click="set_align('center')">中</el-button>
        <el-button type="primary" @click="set_align('right')">右</el-button>
        <el-button type="primary" @click="set_align('justify')">两端</el-button>
      </el-button-group>

      <!-- <el-dropdown
        split-button
        type="primary"
        @click="set_family('Inter')"
        @command="set_family"
      >
        Inter
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="Comic Sans MS, Comic Sans"
            >Comic Sans</el-dropdown-item
          >
          <el-dropdown-item command="serif">serif</el-dropdown-item>
          <el-dropdown-item command="monospace">monospace</el-dropdown-item>
          <el-dropdown-item command="cursive">cursive</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->

      <button @click="set_ul">ul</button>
      <button @click="set_ol">ol</button>
      <button @click="set_task">task</button>
      <button @click="set_blockquote">引用</button>

      <button @click="set_table">表格</button>

      <button @click="insert_horizontalrule">分割线</button>
    </div>
    <div class="editor_wrap">
      <editor-content :editor="editor" class="editor" />
    </div>
    <div id="LeftMenu">
      <el-popover
        v-model="LeftMenuVisible"
        popper-class="zdh-popper-LeftMenu"
        placement="bottom-start"
        width="200"
        trigger="click"
      >
        <div class="LeftMenu_con">
          <div v-show="click_type">
            <div class="title">表格操作</div>
            <div class="item" @click="table_operate(1)">
              <i class="iconfont icon-zuochalie"> </i>
              <div class="name">
                向左插入<input
                  v-model="table_insert.left_col"
                  @input="handleInput"
                  @click.stop
                />列
              </div>
              <div class="space">#</div>
            </div>
            <div class="item" @click="table_operate(2)">
              <i class="iconfont icon-youchalie"> </i>
              <div class="name">
                向右插入<input v-model="table_insert.right_col" @click.stop />列
              </div>
              <div class="space">#</div>
            </div>
            <div class="item" @click="table_operate(3)">
              <i class="iconfont icon-shangchahang"> </i>
              <div class="name">
                向上插入<input v-model="table_insert.top_row" @click.stop />行
              </div>
              <div class="space">#</div>
            </div>
            <div class="item" @click="table_operate(4)">
              <i class="iconfont icon-xiachahang"> </i>
              <div class="name">
                向下插入<input
                  v-model="table_insert.bottom_row"
                  @click.stop
                />行
              </div>
              <div class="space">#</div>
            </div>

            <div class="item" @click="table_operate(5)">
              <i class="iconfont icon-shanchuhang"> </i>
              <div class="name">删除本行</div>
              <div class="space">#</div>
            </div>

            <div class="item" @click="table_operate(6)">
              <i class="iconfont icon-shanchulie"> </i>
              <div class="name">删除本列</div>
              <div class="space">#</div>
            </div>

            <div class="item" @click="table_operate(7)">
              <i class="iconfont icon-fengedanyuange"> </i>
              <div class="name">分割单元格</div>
              <div class="space">#</div>
            </div>

            <div class="item" @click="table_operate(10)">
              <i class="iconfont icon-shanchubiaoge"> </i>
              <div class="name">删除表格</div>
              <div class="space">#</div>
            </div>

            <div class="item" @click="table_operate(9)">
              <i class="iconfont icon-xiufubiaoge"> </i>
              <div class="name">修复表格</div>
              <div class="space">#</div>
            </div>
          </div>
          <div class="item" @click="seshi()">
            <img
              src="@/assets/img/drawio.png"
              style="width: 16px; margin-right: 9px; margin-left: 1px"
            />
            <!-- <i class="iconfont icon-hangneidaima"> </i> -->
            <div class="name">Drawio</div>
            <div class="space">#</div>
          </div>
          <div class="title">基础</div>
          <div class="item" @click="base_operate(0)">
            <i class="iconfont icon-zhengwen"> </i>
            <div class="name">正文</div>
            <div class="space">Ctrl + Alt + 0</div>
          </div>
          <div class="item" @click="base_operate(1)">
            <i class="iconfont icon-H1"> </i>
            <div class="name">标题 1</div>
            <div class="space">Ctrl + Alt + 1</div>
          </div>
          <div class="item" @click="base_operate(2)">
            <i class="iconfont icon-H2"> </i>
            <div class="name">标题 2</div>
            <div class="space">Ctrl + Alt + 2</div>
          </div>
          <div class="item" @click="base_operate(3)">
            <i class="iconfont icon-H3"> </i>
            <div class="name">标题 3</div>
            <div class="space">Ctrl + Alt + 3</div>
          </div>
          <div class="item" @click="base_operate(4)">
            <i class="iconfont icon-H4"> </i>
            <div class="name">标题 4</div>
            <div class="space">Ctrl + Alt + 4</div>
          </div>
          <div class="item" @click="base_operate(5)">
            <i class="iconfont icon-H5"> </i>
            <div class="name">标题 5</div>
            <div class="space">Ctrl + Alt + 5</div>
          </div>
          <div class="item" @click="base_operate(6)">
            <i class="iconfont icon-H6"> </i>
            <div class="name">标题 6</div>
            <div class="space">Ctrl + Alt + 6</div>
          </div>

          <div class="item" @click="base_operate(7)">
            <i class="iconfont icon-wuxuliebiao"> </i>
            <div class="name">无序列表</div>
            <div class="space">#</div>
          </div>
          <div class="item" @click="base_operate(8)">
            <i class="iconfont icon-youxuliebiao"> </i>
            <div class="name">有序列表</div>
            <div class="space">#</div>
          </div>
          <div class="item" @click="base_operate(9)">
            <i class="iconfont icon-daiban"> </i>
            <div class="name">待办列表</div>
            <div class="space">#</div>
          </div>
          <div class="item" @click="base_operate(10)">
            <i class="iconfont icon-yinyong"> </i>
            <div class="name">引用</div>
            <div class="space">#</div>
          </div>

          <div class="title">通用</div>

          <el-popover
            v-model="TableMenuVisible"
            placement="right"
            trigger="hover"
          >
            <div>
              <div v-for="row in 6" :key="row" class="table-box-choose-hang">
                <div
                  v-for="column in 6"
                  :key="column"
                  :data="row + '#' + column"
                  class="table-box-choose-item"
                  :class="show_blue_table(row, column)"
                  @mouseover="handle_box_mouseover"
                  @click="insert_table(row, column)"
                ></div>
              </div>
            </div>
            <div slot="reference" class="item">
              <i class="iconfont icon-biaoge"> </i>
              <div class="name">表格</div>
              <div class="space"># <i class="el-icon-arrow-right"></i></div>
            </div>
          </el-popover>

          <div class="item" @click="insert_horizontalrule">
            <i class="iconfont icon-fengexian"> </i>
            <div class="name">分割线</div>
            <div class="space">#</div>
          </div>
          <div class="item" @click="insert_image">
            <i class="iconfont icon-tupian"> </i>
            <div class="name">图片</div>
            <div class="space">#</div>
          </div>
          <!--
          <div class="item">
            <i class="iconfont icon-lianjie1"> </i>
            <div class="name">链接</div>
            <div class="space">#</div>
          </div> -->
          <el-popover
            v-model="EmojiMenuVisible"
            placement="right"
            trigger="hover"
          >
            <div class="emoji-grid">
              <button
                v-for="(emoji, index) in emojis"
                :key="index"
                @click="insertEmoji(emoji)"
              >
                {{ emoji }}
              </button>
            </div>
            <div slot="reference" class="item">
              <i class="iconfont icon-biaoqing"> </i>
              <div class="name">表情</div>
              <div class="space">#<i class="el-icon-arrow-right"></i></div>
            </div>
          </el-popover>

          <div class="item" @click="insertCode()">
            <i class="iconfont icon-hangneidaima"> </i>
            <div class="name">代码</div>
            <div class="space">#</div>
          </div>
          <div class="title">第三方应用</div>
          <div class="item" @click="insertDrawio()">
            <img
              src="@/assets/img/drawio.png"
              style="width: 16px; margin-right: 9px; margin-left: 1px"
            />
            <!-- <i class="iconfont icon-hangneidaima"> </i> -->
            <div class="name">Drawio</div>
            <div class="space">#</div>
          </div>
          <!-- <div @click="ceshi">测试</div> -->

          <!-- <div class="item">
            <i class="iconfont icon-AIbuchong"> </i>
            <div class="name">AI</div>
            <div class="space">#</div>
          </div> -->
        </div>
        <el-button slot="reference">+</el-button>
      </el-popover>
    </div>

    <div id="BubbleMenu" class="bubble-menu">
      <div v-show="!show_table_menu" class="show_table_menu">
        <el-dropdown class="zdh-el-dropdown-link" trigger="click">
          <el-tooltip :open-delay="1000" content="段落等级" placement="bottom">
            <span style="display: flex; align-items: center">
              <i class="iconfont icon-biaoti"> </i>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </el-tooltip>
          <el-dropdown-menu slot="dropdown" class="zdh-dropdown-bubble">
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(0)">
                <i class="iconfont icon-zhengwen"> </i>
                <div class="name">正文</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(1)">
                <i class="iconfont icon-H1"> </i>
                <div class="name">标题 1</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(2)">
                <i class="iconfont icon-H2"> </i>
                <div class="name">标题 2</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(3)">
                <i class="iconfont icon-H3"> </i>
                <div class="name">标题 3</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(4)">
                <i class="iconfont icon-H4"> </i>
                <div class="name">标题 4</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(5)">
                <i class="iconfont icon-H5"> </i>
                <div class="name">标题 5</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="base_operate(6)">
                <i class="iconfont icon-H6"> </i>
                <div class="name">标题 6</div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-divider direction="vertical"></el-divider>

        <el-dropdown class="zdh-el-dropdown-link" trigger="click">
          <el-tooltip :open-delay="1000" content="对齐方式" placement="bottom">
            <span style="display: flex; align-items: center">
              <i class="iconfont icon-zuoduiqi"> </i
              ><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </el-tooltip>
          <el-dropdown-menu slot="dropdown" class="zdh-dropdown-bubble">
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(0)">
                <i class="iconfont icon-zuoduiqi"> </i>
                <div class="name">左对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(1)">
                <i class="iconfont icon-juzhongduiqi"> </i>
                <div class="name">居中对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(2)">
                <i class="iconfont icon-youduiqi"> </i>
                <div class="name">右对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(3)">
                <i class="iconfont icon-liangduanduiqi"> </i>
                <div class="name">两端对齐</div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-divider direction="vertical"></el-divider>

        <el-tooltip :open-delay="1000" content="粗体" placement="bottom">
          <div class="bubble_item" @click="text_operate(4)">
            <i class="iconfont icon-jiacu1"></i>
          </div>
        </el-tooltip>
        <el-tooltip :open-delay="1000" content="斜体" placement="bottom">
          <div class="bubble_item" @click="text_operate(5)">
            <i class="iconfont icon-xieti1"></i>
          </div>
        </el-tooltip>
        <el-tooltip :open-delay="1000" content="下划线" placement="bottom">
          <div class="bubble_item" @click="text_operate(6)">
            <i class="iconfont icon-xiahuaxian1"></i>
          </div>
        </el-tooltip>
        <el-tooltip :open-delay="1000" content="删除线" placement="bottom">
          <div class="bubble_item" @click="text_operate(7)">
            <i class="iconfont icon-shanchuxian"></i>
          </div>
        </el-tooltip>
        <el-tooltip :open-delay="1000" content="下标" placement="bottom">
          <div class="bubble_item" @click="text_operate(8)">
            <i class="iconfont icon-xiabiao1"></i>
          </div>
        </el-tooltip>
        <el-tooltip :open-delay="1000" content="上标" placement="bottom">
          <div class="bubble_item" @click="text_operate(9)">
            <i class="iconfont icon-shangbiao1"></i>
          </div>
        </el-tooltip>

        <el-popover
          v-model="ColorMenuVisible"
          placement="bottom"
          trigger="click"
        >
          <!-- 默认颜色选项 -->
          <div
            style="
              font-size: 12px;
              font-weight: 600;
              padding-left: 2px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            基础颜色
            <el-button
              size="mini"
              style="font-size: 12px; padding: 5px 8px"
              @click="set_font_color('rgb(23, 23, 23)')"
              >恢复默认
            </el-button>
          </div>
          <div class="color-options">
            <div
              v-for="(colors, index) in defult_font_color"
              :key="index"
              style="display: flex"
            >
              <div
                v-for="color in colors"
                :key="color"
                class="color-option"
                :style="{ color: color }"
                @click="set_font_color(color)"
              >
                A
              </div>
            </div>
          </div>

          <!-- 颜色选择器 -->
          <div class="color-choose">
            其他颜色
            <el-color-picker
              size="mini"
              @change="set_font_color"
            ></el-color-picker>
          </div>

          <!-- 触发 Popover 的内容 -->
          <template #reference>
            <el-tooltip :open-delay="1000" content="文字颜色" placement="bottom">
              <div class="bubble_item">
                <i class="iconfont icon-wenziyanse1"></i>
              </div>
            </el-tooltip>
          </template>
        </el-popover>

        <el-popover
          v-model="BgcolorMenuVisible"
          placement="bottom"
          trigger="click"
        >
          <!-- 默认颜色选项 -->
          <div
            style="
              font-size: 12px;
              font-weight: 600;
              padding-left: 2px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            基础颜色
            <el-button
              size="mini"
              style="font-size: 12px; padding: 5px 8px"
              @click="reset_background_color"
              >恢复默认
            </el-button>
          </div>
          <div class="color-options">
            <div
              v-for="(colors, index) in defult_back_color"
              :key="index"
              style="display: flex"
            >
              <div
                v-for="color in colors"
                :key="color"
                class="color-option"
                :style="{ color: color }"
                @click="set_background_color(color)"
              >
                A
              </div>
            </div>
          </div>

          <div class="color-choose">
            其他颜色
            <el-color-picker
              size="mini"
              @change="set_background_color"
            ></el-color-picker>
          </div>

          <!-- 触发 Popover 的内容 -->
          <template #reference>
            <el-tooltip :open-delay="1000" content="背景颜色" placement="bottom">
              <div class="bubble_item">
                <i class="iconfont icon-beijingyanse1"></i>
              </div>
            </el-tooltip>
          </template>
        </el-popover>

        <span v-show="bubble_menu_is_table">
          <el-divider direction="vertical"></el-divider>
        </span>

        <el-tooltip
          v-show="bubble_menu_is_table"
          :open-delay="1000"
          content="切换到表格操作"
          placement="bottom"
        >
          <div class="bubble_item to_biaoge" @click="show_table_menu = true">
            <i class="iconfont icon-biaogegongjulan"></i>
          </div>
        </el-tooltip>
      </div>

      <div v-show="show_table_menu" class="show_table_menu">
        <el-dropdown class="zdh-el-dropdown-link" trigger="click">
          <el-tooltip :open-delay="1000" content="对齐方式" placement="bottom">
            <span style="display: flex; align-items: center">
              <i class="iconfont icon-zuoduiqi"> </i
              ><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </el-tooltip>
          <el-dropdown-menu slot="dropdown" class="zdh-dropdown-bubble">
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(0)">
                <i class="iconfont icon-zuoduiqi"> </i>
                <div class="name">左对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(1)">
                <i class="iconfont icon-juzhongduiqi"> </i>
                <div class="name">居中对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(2)">
                <i class="iconfont icon-youduiqi"> </i>
                <div class="name">右对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="text_operate(3)">
                <i class="iconfont icon-liangduanduiqi"> </i>
                <div class="name">两端对齐</div>
              </div>
            </el-dropdown-item>

            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="table_operate(11)">
                <i class="iconfont icon-zuoduiqi"> </i>
                <div class="name">顶部对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="table_operate(12)">
                <i class="iconfont icon-juzhongduiqi"> </i>
                <div class="name">居中对齐</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="zdh-dropdown-item" @click="table_operate(13)">
                <i class="iconfont icon-youduiqi"> </i>
                <div class="name">底部对齐</div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-divider direction="vertical"></el-divider>
        <el-tooltip :open-delay="1000" content="删除所选行" placement="bottom">
          <div class="bubble_item" @click="table_operate(5)">
            <i class="iconfont icon-shanchuhang"></i>
          </div>
        </el-tooltip>

        <el-tooltip :open-delay="1000" content="删除所选列" placement="bottom">
          <div class="bubble_item" @click="table_operate(6)">
            <i class="iconfont icon-shanchulie"></i>
          </div>
        </el-tooltip>

        <el-tooltip :open-delay="1000" content="合并单元格" placement="bottom">
          <div class="bubble_item" @click="table_operate(8)">
            <i class="iconfont icon-hebingdanyuange"></i>
          </div>
        </el-tooltip>

        <el-popover
          v-model="TablecolorMenuVisible"
          placement="bottom"
          trigger="click"
        >
          <!-- 默认颜色选项 -->
          <div
            style="
              font-size: 12px;
              font-weight: 600;
              padding-left: 2px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            基础颜色
            <el-button
              size="mini"
              style="font-size: 12px; padding: 5px 8px"
              @click="set_table_background_color('white')"
              >恢复默认
            </el-button>
          </div>
          <div class="color-options">
            <div
              v-for="(colors, index) in defult_back_color"
              :key="index"
              style="display: flex"
            >
              <div
                v-for="color in colors"
                :key="color"
                class="color-option"
                :style="{ color: color }"
                @click="set_table_background_color(color)"
              >
                A
              </div>
            </div>
          </div>

          <!-- 颜色选择器 -->
          <div class="color-choose">
            自定义颜色
            <el-color-picker
              size="mini"
              @change="set_table_background_color"
            ></el-color-picker>
          </div>

          <!-- <el-button
            size="mini"
            style="font-size: 12px"
            @click="set_table_background_color('white')"
            >恢复默认</el-button
          > -->

          <!-- 触发 Popover 的内容 -->
          <template #reference>
            <el-tooltip
              :open-delay="1000"
              content="单元格背景色"
              placement="bottom"
            >
              <div class="bubble_item">
                <i class="iconfont icon-beijingyanse1"></i>
              </div>
            </el-tooltip>
          </template>
        </el-popover>

        <el-tooltip :open-delay="1000" content="删除表格" placement="bottom">
          <div class="bubble_item" @click="table_operate(10)">
            <i class="iconfont icon-shanchubiaoge"></i>
          </div>
        </el-tooltip>
        <el-divider direction="vertical"></el-divider>

        <el-tooltip
          :open-delay="1000"
          content="切换到文本操作"
          placement="bottom"
        >
          <div class="bubble_item to_biaoge" @click="show_table_menu = false">
            <i class="iconfont icon-putonggongjulan"></i>
          </div>
        </el-tooltip>
      </div>
    </div>

    <input
      ref="tiptap-image-fileInput"
      type="file"
      accept="image/*"
      style="display: none"
      @change="handleImageUpload"
    />

    <div
      v-if="showPopup"
      ref="popup"
      class="link-popup"
      :style="{
        top: popupPosition.top + 'px',
        left: popupPosition.left + 'px',
      }"
      @mouseenter="onPopupEnter"
      @mouseleave="onPopupLeave"
    >
      <div class="popup-content">
        <div v-if="!isEditing" class="link-bubble-menu">
          <el-tooltip
            :open-delay="1000"
            class="item"
            effect="dark"
            :content="currentUrl"
            placement="top-start"
          >
            <span class="link-text">{{ currentUrl }}</span>
          </el-tooltip>
          <el-divider direction="vertical"></el-divider>

          <div class="link_edit" @click="startEditing">
            <el-tooltip
              :open-delay="1000"
              class="item"
              effect="dark"
              content="编辑链接"
              placement="top-start"
            >
              <i class="iconfont icon-bianji"></i>
            </el-tooltip>
          </div>
          <div class="link_remove" @click="clearLink">
            <el-tooltip
              :open-delay="1000"
              class="item"
              effect="dark"
              content="移除链接"
              placement="top-start"
            >
              <i class="iconfont icon-a-huaban1111"></i>
            </el-tooltip>
          </div>
          <div class="link_edit" @click="copy_link">
            <el-tooltip
              :open-delay="1000"
              class="item"
              effect="dark"
              content="复制链接"
              placement="top-start"
            >
              <i class="iconfont icon-a-Frame1" style="font-size: 14px"></i>
            </el-tooltip>
          </div>
          <el-divider direction="vertical"></el-divider>

          <el-dropdown class="zdh-el-dropdown-link" trigger="click">
            <el-tooltip :open-delay="1000" content="视图" placement="bottom">
              <span style="display: flex; align-items: center; font-size: 12px">
                链接视图
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </el-tooltip>
            <el-dropdown-menu slot="dropdown" class="zdh-dropdown-bubble">
              <el-dropdown-item>
                <div class="zdh-dropdown-item" @click="card_operate()">
                  <i class="iconfont icon-a-huaban110"> </i>
                  <div class="name">卡片视图</div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="zdh-dropdown-item" @click="webView_operate()">
                  <i class="iconfont icon-a-huaban2"> </i>
                  <div class="name">网页视图</div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <button @click="startEditing">编辑 URL</button> -->
          <!-- <button @click="clearLink">清空链接</button>
          <button @click="clearLinkAndText">清空链接和文字</button> -->
        </div>
        <div v-else class="link-bubble-menu">
          <input v-model="editableUrl" type="text" class="link-input" />
          <div class="save_link" @click="saveEdit">保存</div>
        </div>
      </div>
    </div>
    <!-- <div class="RightMenu" id="RightMenu">

    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import personalAvatar from "@/components/personal";
import { get_tenant_user_list } from "@/network/user/index.js";
import { ossAuthorization } from "@/utils/oss";

// 编辑器
import { Editor, EditorContent, VueNodeViewRenderer } from "@tiptap/vue-2";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { getText, getTextSerializersFromSchema } from "@tiptap/core";
import GlobalDragHandle from "tiptap-extension-global-drag-handle";
import { getAttributes } from "@tiptap/core";
// import { InlineCustom } from "./extensions/InlineCustomExtension";
// 自定义扩展
import drawioComponent from "./extensions/drawio.js";
// import zdhComponent from "./extensions/zdh.js";
import CodeBlockComponent from "./extensions/CodeBlockComponent.vue";
import ImageComponent from "./extensions/ImageComponent.vue";

// import CodeBlockComponent from "./extensions/1.vue";
// 协同编辑
import Collaboration from "@tiptap/extension-collaboration";
import { HocuspocusProvider } from "@hocuspocus/provider";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import suggestion from "./extensions/suggestion.js";

// 基础扩展
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Paragraph from "@tiptap/extension-paragraph";
// 字体类
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Placeholder from "@tiptap/extension-placeholder";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import FloatingMenu from "@tiptap/extension-floating-menu";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Mention from "@tiptap/extension-mention";
import Link from "@tiptap/extension-link";
// 第三方拓展
import UniqueId from "tiptap-unique-id";
import OfficePaste from "@intevation/tiptap-extension-office-paste";
import { all, createLowlight } from "@/utils/lowlight";
import { ImageResize } from "@/utils/tiptap-extension-resize-image";
export default {
  ...mapGetters(["user_list"]),

  components: {
    personalAvatar,
    EditorContent,
  },

  data() {
    return {
      showPopup: false,
      isEditing: false,
      currentUrl: "",
      editableUrl: "",
      selectedLink: null,
      popupPosition: { top: 0, left: 0 },
      link_view: null,

      is_mac: false,
      is_all: false,
      isEditable: true,
      show_table_menu: false,
      bubble_menu_is_table: false,
      show_co_status: false,
      table_insert: {
        left_col: 1,
        right_col: 1,
        top_row: 1,
        bottom_row: 1,
      },
      click_type: false,
      table_choose_row: 0,
      table_choose_column: 0,
      co_status: "Disconnected",
      co_color: "",
      co_list: [],
      show_menu: false,
      LeftMenuVisible: false,
      TopMenuVisible: false,
      TableMenuVisible: false,
      EmojiMenuVisible: false,
      ColorMenuVisible: false,
      BgcolorMenuVisible: false,
      TablecolorMenuVisible: false,
      menu_data: {
        view: null,
        event: null,
      },
      userInfo: {
        user_id: this.$store.getters.user_info.accountId,
        userName: this.$store.getters.name,
        avatar: this.$store.getters.avatar,
      },
      font_color: "rgba(255, 69, 0)",
      background_color: "rgba(255, 69, 0)",
      defult_back_color: [
        [
          "#f5f5f5", // 纯白
          "#d5d5d5", // 浅灰
          "#aaaaaa", // 中灰
          "#808080", // 灰色
          "#555555", // 深灰
          "#2b2b2b", // 接近黑色
          "#000000", // 纯黑
        ],
        [
          "#ff9e66",
          "#ffbf80",
          "#ffeb99",
          "#d9f7d9",
          "#a3ffe9",
          "#a9d5ff",
          "#ffb6e0",
        ],
        [
          "#cc3700",
          "#cc7000",
          "#ccac00",
          "#73bb73",
          "#009ea1",
          "#1873cc",
          "#9f0f68",
        ],
      ],
      defult_font_color: [
        [
          "#f5f5f5", // 纯白
          "#d5d5d5", // 浅灰
          "#aaaaaa", // 中灰
          "#808080", // 灰色
          "#555555", // 深灰
          "#2b2b2b", // 接近黑色
          "#000000", // 纯黑
        ],
        [
          "#ff9e66",
          "#ffbf80",
          "#ffeb99",
          "#d9f7d9",
          "#66e9eb",
          "#80c1ff",
          "#f280c1",
        ],
        [
          "#ff4500",
          "#ff8c00",
          "#ffd700",
          "#90ee90",
          "#00ced1",
          "#1e90ff",
          "#c71585",
        ],
        [
          "#cc3700",
          "#cc7000",
          "#ccac00",
          "#73bb73",
          "#009ea1",
          "#1873cc",
          "#9f0f68",
        ],
      ],
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
      ],

      editor: null,
      heading_options: [
        { label: "h1", value: 1 },
        { label: "h2", value: 2 },
        { label: "h3", value: 3 },
        { label: "h4", value: 4 },
        { label: "h5", value: 5 },
        { label: "h6", value: 6 },
      ],
      emojis: [
        "😀",
        "😃",
        "😄",
        "😁",
        "😆",
        "😅",
        "😂",
        "🤣",
        "😊",
        "😇",
        "🙂",
        "🙃",
        "😉",
        "😌",
        "😍",
        "🥰",
        "😘",
        "😗",
        "😙",
        "😚",
        "😋",
        "😛",
        "😝",
        "😜",
        "🤪",
        "🤨",
        "🧐",
        "🤓",
        "😎",
        "🥸",
        "🤩",
        "🥳",
        "😏",
        "😒",
        "😞",
        "😔",
        "😟",
        "😕",
        "🙁",
        "☹️",
        "😣",
        "😖",
        "😫",
        "😩",
        "🥺",
        "😢",
        "😭",
        "😤",
        "😠",
        "😡",
        "🤬",
        "🤯",
        "😳",
        "🥵",
        "🥶",
        "😱",
        "😨",
        "😰",
        "😥",
        "😓",
        "🤗",
        "🤔",
        "🤭",
        "🤫",
        "🤥",
        "😶",
        "😐",
        "😑",
        "😬",
        "🙄",
        "😯",
        "😦",
        "😧",
        "😮",
        "😲",
        "🥱",
        "😴",
        "🤤",
        "😪",
        "😵",
      ],
    };
  },

  watch: {
    isEditable(value) {
      this.editor.setEditable(value);
    },
  },
  mounted() {
    ossAuthorization.call(this);

    this.is_all = true;

    // if (this.$route.query.is_all && this.$route.query.is_all=='all') {
    //   this.is_all=true
    // }

    this.co_color = this.getRandomColor();
    const provider = new HocuspocusProvider({
      url: "ws://1.13.169.169:30342/52c21b08-6b32-4b82-9c5d-67a23e6e50b3/1234567890",
      name: "jjjjj",
    });
    let userList = {};

    get_tenant_user_list().then((res) => {
      localStorage.setItem("zdh_tiptap_userlist", JSON.stringify(res.content));
      res.content.forEach((e) => {
        userList[e.accountId] = e;
      });
      this.show_co_status = true;
    });
    // Listen for updates to the states of all users
    provider.on("awarenessChange", ({ states }) => {
      this.co_list = [];
      // let s = new Set();
      // if (this.show_co_status) {
      //   for (let i of states) {
      //     if (i.user.accountId && !s.has(i.user.accountId)) {
      //       s.add(i.user.accountId);
      //       this.co_list.push(userList[i.user.accountId]);
      //     }
      //   }
      // }
    });
    provider.on("connect", () => {
      this.showStatus("Connected");
    });
    provider.on("disconnect", () => {
      this.showStatus("Disconnected");
    });
    let self = this;
    const lowlight = createLowlight(all);

    this.editor = new Editor({
      autofocus: true,
      extensions: [
        Link.configure({
          openOnClick: false,
        }).extend({
          addProseMirrorPlugins() {
            const plugins = this.parent?.() || [];

            const ctrlClickHandler = new Plugin({
              key: new PluginKey("handleControlClick"),
              props: {
                handleClick(view, pos, event) {
                  const attrs = getAttributes(view.state, "link");
                  const link = event.target?.closest("a");

                  const keyPressed = event.ctrlKey || event.metaKey;

                  if (keyPressed && link && attrs.href) {
                    window.open(attrs.href, attrs.target);

                    return true;
                  }

                  return false;
                },
              },
            });
            // 处理鼠标悬浮显示按钮，并替换文本
            const hoverHandler = new Plugin({
              key: new PluginKey("handleHover"),
              props: {
                handleDOMEvents: {
                  mouseover(view, event) {
                    const link = event.target?.closest("a");
                    if (!link) return;

                    const attrs = getAttributes(view.state, "link");
                    if (!attrs.href) return;

                    self.show_link_menu(event, link, attrs.href, view);
                  },
                },
              },
            });
            const hoverHandler1 = new Plugin({
              key: new PluginKey("handleHover"),
              props: {
                handleDOMEvents: {
                  mouseover(view, event) {
                    const link = event.target?.closest("a");

                    if (link) {
                      const attrs = getAttributes(view.state, "link");

                      if (attrs.href) {
                        // 显示 tooltip，可以替换为你的 tooltip 逻辑
                        const tooltip = document.createElement("div");
                        tooltip.innerText =
                          "按住Ctrl点击以打开链接：  " + attrs.href;
                        tooltip.style.position = "absolute";
                        tooltip.style.background = "rgba(0,0,0,0.75)";
                        tooltip.style.color = "#fff";
                        tooltip.style.padding = "4px 8px";
                        tooltip.style.borderRadius = "4px";
                        tooltip.style.fontSize = "12px";
                        tooltip.style.pointerEvents = "none";
                        tooltip.style.top = `${event.clientY + 10}px`;
                        tooltip.style.left = `${event.clientX + 10}px`;
                        tooltip.style.zIndex = "1000";
                        tooltip.classList.add("custom-tooltip");

                        document.body.appendChild(tooltip);

                        link.addEventListener(
                          "mouseleave",
                          () => {
                            document
                              .querySelectorAll(".custom-tooltip")
                              .forEach((el) => el.remove());
                          },
                          { once: true }
                        );
                      }
                    }
                  },
                },
              },
            });

            plugins.push(ctrlClickHandler, hoverHandler, hoverHandler1);

            return plugins;
          },
        }),
        GlobalDragHandle,
        drawioComponent,
        // InlineCustom,

        Text,
        TextStyle,
        OrderedList,
        OfficePaste,
        Image.configure({
          inline: true,
        }),
        Underline,
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion,
          // renderHTML({ options, node }) {
          //   let con=document.createElement('div')
          //   console.log( options, node);

          //   con.innerText=`${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
          //   return con;
          // },
        }),
        Color,
        Superscript,
        // Link.extend({
        //   addNodeView() {
        //     return VueNodeViewRenderer(LinkComponent);
        //   },
        // }),
        CodeBlockLowlight.extend({
          addNodeView() {
            return VueNodeViewRenderer(CodeBlockComponent);
          },
        }).configure({ lowlight }),
        Placeholder.configure({
          // Use a placeholder:
          // placeholder: "点击 + 快捷插入组件",
          // Use different placeholders depending on the node type:
          placeholder: ({ node }) => {
            // self.click_type = node.type.name;
            return "点击 + 快捷插入组件";
          },
        }),
        // Paragraph.extend({
        //   name: "customExtension",
        //   addProseMirrorPlugins() {
        //     return [
        //       new Plugin({
        //         key: new PluginKey("eventHandler"),
        //         props: {
        //           handleClick(view, pos, event) {
        //             console.log(view, pos, event);
        //             let p=event.target.parentElement.nodeName

        //             // if (event.target.nodeName == "P" && p!='TH'&& p!='TD') {
        //             //   console.log(event.target.parentElement.nodeName);

        //             //   self.menu_data.view = view;
        //             //   self.menu_data.event = event;
        //             //   self.menu_data.pos = pos;
        //             //   self.show_pos();
        //             // }
        //           },
        //           handleDOMEvents: {
        //             handleClick(view, pos, event) {},
        //           },
        //           // … and many, many more.
        //           // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
        //         },
        //       }),
        //     ];
        //   },
        // }),
        Subscript,
        // drawIoExtension.configure({
        //   openDialog: "dblclick",
        // }),
        ImageResize,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader.extend({
          addAttributes() {
            return {
              ...this.parent?.(),

              borderStyle: {
                default: null,
                parseHTML: (element) => element.style.borderStyle || "",
                renderHTML: (attributes) => {
                  return {
                    style: `border: ${attributes.borderStyle}`,
                  };
                },
              },
              verticalAlign: {
                default: "top",
                renderHTML: (attributes) => {
                  if (!attributes.verticalAlign) {
                    return {};
                  }

                  return {
                    style: `vertical-align: ${attributes.verticalAlign}`,
                  };
                },
                parseHTML: (element) => {
                  return element.style.verticalAlign || "top";
                },
              },
              backgroundColor: {
                default: null,
                renderHTML: (attributes) => {
                  if (!attributes.backgroundColor) {
                    return {};
                  }

                  return {
                    style: `background-color: ${attributes.backgroundColor}`,
                  };
                },
                parseHTML: (element) => {
                  return element.style.backgroundColor.replace(/['"]+/g, "");
                },
              },
            };
          },
        }),
        TableCell.extend({
          addAttributes() {
            return {
              ...this.parent?.(),
              borderStyle: {
                default: null,
                parseHTML: (element) => element.style.borderStyle || "",
                renderHTML: (attributes) => {
                  return {
                    style: `border: ${attributes.borderStyle}`,
                  };
                },
              },
              verticalAlign: {
                default: "top",
                renderHTML: (attributes) => {
                  if (!attributes.verticalAlign) {
                    return {};
                  }

                  return {
                    style: `vertical-align: ${attributes.verticalAlign}`,
                  };
                },
                parseHTML: (element) => {
                  return element.style.verticalAlign || "top";
                },
              },
              backgroundColor: {
                default: null,
                renderHTML: (attributes) => {
                  if (!attributes.backgroundColor) {
                    return {};
                  }

                  return {
                    style: `background-color: ${attributes.backgroundColor}`,
                  };
                },
                parseHTML: (element) => {
                  return element.style.backgroundColor.replace(/['"]+/g, "");
                },
              },
            };
          },
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        FontFamily,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Highlight.configure({ multicolor: true }),
        UniqueId.configure({
          attributeName: "id",
          types: [
            "paragraph",
            "heading",
            "orderedList",
            "bulletList",
            "listItem",
          ],
          createId: () => window.crypto.randomUUID(),
        }),
        BubbleMenu.configure({
          tippyOptions: {
            placement: "left",
            maxWidth: "none",
          },
          element: document.querySelector("#BubbleMenu"),
          shouldShow: ({ editor, view, state, oldState, from, to }) => {
            // if (editor.isActive("link")) {
            //   return true
            // }
            if (!self.is_all) {
              return false;
            }
            if (!self.isEditable) {
              return false;
            }
            self.bubble_menu_is_table = editor.isActive("table");
            // only show the bubble menu for images and links
            // console.log(editor.isActive("codeBlock") , editor.isActive("table") , editor.isActive("link"), editor.isActive("paragraph"), editor.isActive("heading"));
            if (from == to) {
              return false;
            }
            if (
              editor.isActive("image") ||
              editor.isActive("drawioComponent")
            ) {
              return false;
            }
            self.show_table_menu = false;
            return !editor.isActive("codeBlock");
          },
        }),
        FloatingMenu.configure({
          element: document.querySelector("#LeftMenu"),
          tippyOptions: {
            placement: "left",
          },
          shouldShow: (a) => {
            if (!self.isEditable) {
              return false;
            }
            self.table_choose_row = 0;
            self.table_choose_column = 0;
            const { editor, view, state, oldState } = a;
            const { selection } = state;
            const { $anchor } = selection;
            function getTextContent(node) {
              return getText(node, {
                textSerializers: getTextSerializersFromSchema(
                  self.editor.schema
                ),
              });
            }
            const { from, to } = state.selection;
            if (from != to) {
              return false;
            }

            const isEmptyTextBlock =
              $anchor.parent.isTextblock &&
              !$anchor.parent.type.spec.code &&
              !$anchor.parent.textContent &&
              $anchor.parent.childCount === 0 &&
              !getTextContent($anchor.parent);

            if (editor.isActive("table")) {
              return true;
            }
            if (
              editor.isActive("blockquote") ||
              editor.isActive("bulletList") ||
              editor.isActive("taskList") ||
              editor.isActive("orderedList")
            ) {
              return false;
            }

            return isEmptyTextBlock;
          },
        }),
        StarterKit.configure({
          history: false,
          heading: {
            levels: [1, 2, 3, 4, 5, 6],
          },
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            accountId: this.userInfo.user_id,
            name: this.userInfo.userName,
            color: this.co_color,
          },
        }),
        Collaboration.configure({
          document: provider.document,
        }),
      ],
      editorProps: {
        handlePaste: function (view, event, slice) {
          ossAuthorization.call(self);

          const items = Array.from(event.clipboardData?.items || []);
          for (const item of items) {
            if (item.type.indexOf("image") === 0) {
              const file = item.getAsFile();

              self.client
                .put(`tiptap/upload/${new Date().getTime()}${file.name}`, file)
                .then((res) => {
                  self.editor.commands.setImage({
                    src: res.url,
                  });
                  self.editor
                    .chain()
                    .insertContentAt(view.state.doc.content.size, {
                      type: "paragraph",
                    })
                    .run();
                });

              return true;
            }
          }

          return false;
        },
      },
    });
    this.editor.on("selectionUpdate", ({ editor }) => {
      this.click_type = this.editor.isActive("table");
    });

    this.editor.on("update", ({ editor }) => {
      const { state } = editor;
      const lastNode = state.doc.lastChild;

      // 如果用户正在输入（光标在文档末尾），则不插入空段落
      const isCursorAtEnd = state.selection.to === state.doc.content.size;
      if (isCursorAtEnd) {
        return;
      }

      // 检查最后一个节点是否为空段落
      if (
        lastNode &&
        lastNode.type.name === "paragraph" &&
        lastNode.content.size === 0
      ) {
        return;
      }

      // 保存当前光标位置
      const { from, to } = state.selection;

      // 在文档末尾插入空段落
      editor
        .chain()
        .insertContentAt(state.doc.content.size, {
          type: "paragraph",
        })
        .setTextSelection({ from, to }) // 恢复光标位置
        .run();
    });
    setTimeout(() => {
      this.editor.commands.focus("start");
    }, 500);
  },
  methods: {
    onPopupEnter() {
      this.keepPopupVisible = true;
    },

    // 鼠标移出浮窗
    onPopupLeave() {
      this.keepPopupVisible = false;
      setTimeout(() => {
        if (!this.keepPopupVisible) {
          this.showPopup = false;
        }
      }, 50000);
    },

    // 开始编辑
    startEditing() {
      this.isEditing = true;
    },

    // 保存 URL
    saveEdit() {
      if (!this.selectedLink || !this.link_view) return;

      const { state, dispatch } = this.link_view;
      const pos = this.link_view.posAtDOM(this.selectedLink);
      const endPos = pos + this.selectedLink.textContent.length;

      const tr = state.tr;

      // 1. 先移除链接（如果用户清空了 URL）
      tr.removeMark(pos, endPos, state.schema.marks.link);

      // 2. 如果 URL 为空，仅移除链接，不再设置新链接
      if (this.editableUrl.trim() === "") {
        dispatch(tr);
        this.isEditing = false;
        this.showPopup = false;
        return;
      }

      // 3. 重新添加新的链接
      const linkMark = state.schema.marks.link.create({
        href: this.editableUrl,
      });
      tr.addMark(pos, endPos, linkMark);

      // 4. 提交变更
      dispatch(tr);

      this.isEditing = false;
      this.showPopup = false;
    },
    copy_link() {
      let key = this.selectedLink;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(key);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = key;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
    },
    // 清空链接
    clearLink() {
      if (!this.selectedLink || !this.link_view) return;

      const { state, dispatch } = this.link_view;
      const pos = this.link_view.posAtDOM(this.selectedLink);
      const endPos = pos + this.selectedLink.textContent.length;

      const tr = state.tr.removeMark(pos, endPos, state.schema.marks.link);
      dispatch(tr);
      this.showPopup = false;
    },

    // 清空链接和文本
    clearLinkAndText() {
      if (!this.selectedLink || !this.link_view) return;

      const { state, dispatch } = this.link_view;
      const pos = this.link_view.posAtDOM(this.selectedLink);
      const endPos = pos + this.selectedLink.textContent.length;

      const tr = state.tr.delete(pos, endPos);
      dispatch(tr);
      this.showPopup = false;
      // this.editor.commands.insertDrawIo()
      this.editor
        .chain()
        .focus()
        .insertdrawioComponent({
          url: "data:image/svg+xml;base64,",
          id: "ctvygn",
          styles: {
            imageWidth: 200,
            imageHeight: 200,
            imgJustifyContent: "center",
          },
        })
        .run();
    },
    show_link_menu(event, linkElement, url, view) {
      console.log(event, linkElement, url, view);

      this.currentUrl = url;
      this.editableUrl = url;
      this.selectedLink = linkElement;
      this.showPopup = true;

      // 计算浮窗位置
      const rect = linkElement.getBoundingClientRect();
      this.popupPosition = {
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX,
      };

      this.link_view = view;
    },
    export_json() {
      function saveJsonToFile(jsonData, filename = "data.json") {
        // 将 JSON 数据转换为字符串
        const jsonString = JSON.stringify(jsonData, null, 2);

        // 创建 Blob 对象
        const blob = new Blob([jsonString], { type: "application/json" });

        // 创建下载链接
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;

        // 触发下载
        document.body.appendChild(a);
        a.click();

        // 清理
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
      saveJsonToFile(this.editor.getJSON());
    },
    ceshi() {},
    insert_image() {
      this.$refs["tiptap-image-fileInput"].click();
    },
    uploadImage(file) {
      const self = this;
      self.client
        .put(`tiptap/upload/${new Date().getTime()}${file.name}`, file)
        .then((res) => {
          self.editor.commands.setImage({
            src: res.url,
          });
          self.editor
            .chain()
            .insertContentAt(self.editor.state.doc.content.size, {
              type: "paragraph",
            })
            .run();
        });
    },
    handleImageUpload(event) {
      const file = event.target.files[0];

      if (file && file.type.startsWith("image/")) {
        this.uploadImage(file);
      }
    },
    insert_table(row, column) {
      this.TableMenuVisible = false;
      this.LeftMenuVisible = false;
      this.set_table(row, column);
    },
    show_blue_table(row, column) {
      if (row <= this.table_choose_row && column <= this.table_choose_column) {
        return "boxactive";
      } else {
        return "";
      }
    },
    handle_box_mouseover(e) {
      let l = e.target.attributes.data.nodeValue.split("#");
      this.table_choose_row = l[0];
      this.table_choose_column = l[1];
    },
    showStatus(e) {
      this.co_status = e;
    },
    seshi() {
      console.log(213456);

      this.editor.commands.insertInlineCustom("content");
      // this.editor.commands.setCellAttribute("customAttribute", "value");
      // this.editor
      //   .chain()
      //   .focus()
      //   .setCellAttribute("backgroundColor ", "1px solie red")
      //   .run();
    },
    mark_font_color() {
      if (!this.font_color) {
        this.editor.chain().focus().unsetColor().run();
      } else {
        this.editor.chain().focus().setColor(this.font_color).run();
      }
    },
    mark_background_color() {
      if (!this.background_color) {
        this.editor.chain().focus().unsetHighlight().run();
      } else {
        this.editor
          .chain()
          .focus()
          .setHighlight({ color: this.background_color })
          .run();
      }
    },
    mark_up() {
      this.editor.chain().focus().toggleSuperscript().run();
    },
    mark_down() {
      this.editor.chain().focus().toggleSubscript().run();
    },
    mark_s() {
      this.editor.chain().focus().toggleStrike().run();
    },
    mark_u() {
      this.editor.chain().focus().toggleUnderline().run();
    },
    mark_b() {
      this.editor.chain().focus().toggleBold().run();
    },
    mark_i() {
      this.editor.chain().focus().toggleItalic().run();
    },
    hide_pos() {
      document.querySelector("#leftHandle").style.visibility = "hidden";
    },
    show_pos() {
      let y = this.menu_data.event.target.getBoundingClientRect().top;
      document.querySelector("#leftHandle").style.top = y + "px";
      document.querySelector("#leftHandle").style.visibility = "visible";
    },
    set_heading(heading) {
      if (heading == 0) {
        this.editor.chain().focus().toggleHeading({ level: 6 }).run();
        this.editor.chain().focus().toggleHeading({ level: 6 }).run();
      } else {
        this.editor
          .chain()
          .focus()
          .toggleHeading({ level: parseInt(heading) })
          .run();
      }
    },
    set_table(row, column) {
      this.editor
        .chain()
        .focus()
        .insertTable({ rows: row, cols: column })
        .run();
    },
    set_family(family) {
      this.editor.chain().focus().setFontFamily(family).run();
    },
    set_align(text_align) {
      this.editor.chain().focus().setTextAlign(text_align).run();
    },
    set_ul() {
      this.editor.chain().focus().toggleBulletList().run();
    },
    set_ol() {
      this.editor.chain().focus().toggleOrderedList().run();
    },
    tab_ul() {
      this.editor.chain().focus().sinkListItem("listItem").run();
    },
    shift_ul() {
      this.editor.chain().focus().liftListItem("listItem").run();
    },
    set_task() {
      this.editor.chain().focus().toggleTaskList().run();
    },
    insert_horizontalrule() {
      this.LeftMenuVisible = false;
      this.editor.chain().focus().setHorizontalRule().run();
    },
    set_blockquote() {
      // <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
      //   <button @click="editor.chain().focus().setBlockquote().run()" :disabled="!editor.can().setBlockquote()">
      //   <button @click="editor.chain().focus().unsetBlockquote().run()" :disabled="!editor.can().unsetBlockquote()">
      this.editor.chain().focus().toggleBlockquote().run();
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    generateRandomString(length) {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    handleInput(event) {
      let value = event.target.value.replace(/[^0-9]/g, "");
      if (value === "" || parseInt(value) <= 0) {
        value = "";
      }
      event.target.value = value; // 更新输入框的值
    },
    insertDrawio() {
      let myDate =
        new Date().getTime().toString() +
        "random" +
        Math.ceil(Math.random() * 100).toString() +
        "user" +
        this.$store.getters.userAccountId;
      let i = "draw" + myDate;
      // this.editor.commands.insertDrawIo()
      this.editor
        .chain()
        .focus()
        .insertdrawioComponent({
          url: "data:image/svg+xml;base64,",
          id: i,
          styles: {
            imageWidth: 200,
            imageHeight: 200,
            imgJustifyContent: "center",
          },
        })
        .run();
    },
    insertCode() {
      this.LeftMenuVisible = false;
      this.editor.commands.setCodeBlock();
    },
    insertEmoji(emoji) {
      this.LeftMenuVisible = false;
      this.EmojiMenuVisible = false;
      this.editor.commands.insertContent(emoji);
    },
    base_operate(ops) {
      this.LeftMenuVisible = false;
      if (ops == 0) {
        this.editor.chain().focus().setParagraph().run();
      }
      if (ops == 1) {
        this.editor.chain().focus().toggleHeading({ level: 1 }).run();
      }
      if (ops == 2) {
        this.editor.chain().focus().toggleHeading({ level: 2 }).run();
      }
      if (ops == 3) {
        this.editor.chain().focus().toggleHeading({ level: 3 }).run();
      }
      if (ops == 4) {
        this.editor.chain().focus().toggleHeading({ level: 4 }).run();
      }
      if (ops == 5) {
        this.editor.chain().focus().toggleHeading({ level: 5 }).run();
      }
      if (ops == 6) {
        this.editor.chain().focus().toggleHeading({ level: 6 }).run();
      }

      if (ops == 7) {
        this.editor.chain().focus().toggleBulletList().run();
      }
      if (ops == 8) {
        this.editor.chain().focus().toggleOrderedList().run();
      }
      if (ops == 9) {
        this.editor.chain().focus().toggleTaskList().run();
      }

      if (ops == 10) {
        this.editor.chain().focus().toggleBlockquote().run();
      }
    },
    text_operate(ops) {
      if (ops == 0) {
        this.editor.chain().focus().setTextAlign("left").run();
      }
      if (ops == 1) {
        this.editor.chain().focus().setTextAlign("center").run();
      }
      if (ops == 2) {
        this.editor.chain().focus().setTextAlign("right").run();
      }
      if (ops == 3) {
        this.editor.chain().focus().setTextAlign("justify").run();
      }
      if (ops == 4) {
        this.editor.chain().focus().toggleBold().run();
      }
      if (ops == 5) {
        this.editor.chain().focus().toggleItalic().run();
      }
      if (ops == 6) {
        this.editor.chain().focus().toggleUnderline().run();
      }

      if (ops == 7) {
        this.editor.chain().focus().toggleStrike().run();
      }
      if (ops == 8) {
        this.editor.chain().focus().toggleSubscript().run();
      }
      if (ops == 9) {
        this.editor.chain().focus().toggleSuperscript().run();
      }
    },
    table_operate(ops) {
      this.LeftMenuVisible = false;

      if (ops == 1) {
        for (let i = 0; i < this.table_insert.left_col; i++) {
          this.editor.chain().focus().addColumnBefore().run();
        }
      }
      if (ops == 2) {
        for (let i = 0; i < this.table_insert.right_col; i++) {
          this.editor.chain().focus().addColumnAfter().run();
        }
      }
      if (ops == 3) {
        for (let i = 0; i < this.table_insert.top_row; i++) {
          this.editor.chain().focus().addRowBefore().run();
        }
      }
      if (ops == 4) {
        for (let i = 0; i < this.table_insert.bottom_row; i++) {
          this.editor.chain().focus().addRowAfter().run();
        }
      }
      if (ops == 5) {
        this.editor.chain().focus().deleteRow().run();
      }
      if (ops == 6) {
        this.editor.chain().focus().deleteColumn().run();
      }

      if (ops == 7) {
        this.editor.chain().focus().splitCell().run();
      }
      if (ops == 8) {
        this.editor.chain().focus().mergeCells().run();
      }
      if (ops == 9) {
        this.editor.chain().focus().fixTables().run();
      }

      if (ops == 10) {
        this.editor.chain().focus().deleteTable().run();
      }
      if (ops == 11) {
        this.editor
          .chain()
          .focus()
          .setCellAttribute("verticalAlign", "top")
          .run();
      }
      if (ops == 12) {
        this.editor
          .chain()
          .focus()
          .setCellAttribute("verticalAlign", "middle")
          .run();
      }
      if (ops == 13) {
        this.editor
          .chain()
          .focus()
          .setCellAttribute("verticalAlign", "bottom")
          .run();
      }
    },
    set_font_color(color) {
      this.ColorMenuVisible = false;
      this.editor.chain().focus().setColor(color).run();
    },
    set_background_color(color) {
      this.BgcolorMenuVisible = false;

      this.editor.chain().focus().setHighlight({ color: color }).run();
    },
    set_table_background_color(color) {
      this.TablecolorMenuVisible = false;
      this.editor
        .chain()
        .focus()
        .setCellAttribute("backgroundColor", color)
        .run();
    },
    reset_background_color() {
      this.editor.chain().focus().unsetHighlight().run();
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss" scoped>
.link-popup {
  border-radius: 3px;
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  padding: 4px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.link-bubble-menu {
  display: flex;
  align-items: center;
  .link-input {
    border: none;
    border-bottom: 1px solid #dfe1e5;
  }
  .save_link {
    font-size: 12px;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background: #f7f8fa;
    }
  }
  .link-text {
    // margin-right: 5px;

    padding-left: 5px;
    max-width: 200px;
    color: #606266;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .iconfont {
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background: #f7f8fa;
    }
  }
}
.tool_btn :hover {
  background: #dfe1e5;
  border-radius: 3px;

  padding: 5px;
}
.toolbar {
  padding: 10px 0;
  border: 1px solid #dfe1e5;
  display: flex;
  align-items: center;
  justify-content: center;
  .tool_btn {
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
    padding: 5px;
  }
}
.topbar {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.editor_wrap {
  flex: 1;
  overflow: auto;
  width: 100%;
  padding: 10px 0 100px 0;
}
.right-group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.editor_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

::v-deep ::selection {
  background: #e3eeff;
}
.co-list {
  padding: 4px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  .colist-pannle {
    background: red;
    width: 300px;
    height: 200px;
    position: absolute;
    top: 50px;
    right: 0px;
  }
}
.co-p {
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.co-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(34, 197, 94);
  margin-right: 5px;
}
.co-li {
  display: flex;
  align-items: center;
  padding: 5px 0;
  gap: 10px;
  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.88);
  }
}
.co-list .co-avatar:not(:first-child) {
  transform: translateX(-50%);
}
#leftHandle {
  // display: none;
  visibility: hidden;
  width: 25px;
  height: 25px;
  // background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 4px;
  left: 10vw;
  top: 50px;
}
.table-box-choose-hang {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .table-box-choose-item {
    margin: 5px;
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .boxactive {
    background: #0064ff;
  }
}
.LeftMenu_con {
  .title {
    padding: 2px 10px;
    background: #f7f8fa;
    font-size: 12px;
    color: #87888a;
  }
  .item {
    cursor: pointer;
    font-size: 14px;
    padding: 6px 10px;
    color: #2d2d2e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconfont {
      margin-right: 10px;
    }
    .name {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input {
        margin: 0 5px;
        width: 25px;
        text-align: center;
      }
    }
    .space {
      color: rgb(144, 144, 144);
      font-size: 12px;
    }
  }
  .item:hover {
    background: #f7f8fa;
  }
}
.RightMenu {
  width: 200px;
  height: 300px;
  background: red;
  position: fixed;
  top: 0;
  left: 0;
}
.bubble-menu {
  height: 40px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(45, 45, 46, 0.15) 0px 8px 16px -4px,
    rgba(45, 45, 46, 0.2) 0px 0px 1px 0px;
  padding: 0px 10px;
  gap: 5px;
  border-radius: 4px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .show_table_menu {
    display: flex;
    align-items: center;
  }
  .devideline {
    margin: 0 5px;
    background: #d1d1d1;
    height: 10px;
    width: 2px;
  }
  .to_biaoge {
    // border: 1px solid black;
  }
  .bubble_item {
    margin-right: 1px;
    padding: 3px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .bubble_item:hover {
    background: #f7f8fa;
  }
}
.color-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 5px;
}
.color-choose {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 12px;
  font-weight: 600;
  margin-left: 2px;
  gap: 5px;
}
.color-option {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin: 2px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.color-option:hover {
  border-color: #0064ff;
}
</style>
<style lang="scss">
.emoji-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8 列 */

  // grid-template-rows: repeat(10, 1fr); /* 10 行 */
  gap: 5px; /* 按钮之间的间距 */
  // margin-bottom: 16px; /* 与编辑器的间距 */
}

.emoji-grid button {
  font-size: 14px; /* Emoji 大小 */
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.emoji-grid button:hover {
  background-color: #e0e0e0;
}
.zdh-dropdown-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  .iconfont {
    margin-right: 10px;
  }
}
.zdh-el-dropdown-link {
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 3px;
}
.zdh-el-dropdown-link:hover {
  background: #f7f8fa;
}
.zdh-dropdown-bubble {
  .el-dropdown-menu__item {
    padding: 0 30px 0 10px;
    line-height: 30px;
  }
  .el-dropdown-menu__item:hover {
    background: #f7f8fa;
    color: #606266;
  }
}
.zdh-popper-LeftMenu {
  padding: 10px 0 !important;
  height: 300px;
  overflow-y: auto;
}
/* Bubble menu */
p {
  margin-top: 15px;
  margin-bottom: 15px;
}
.editor {
  width: 75vw;
  // max-height: 80vh;
  // overflow: auto;
  margin: 0 auto;
}
.tiptap p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
/* Basic editor styles */
.tiptap {
  font-size: 15px;
  text-align: left;
  color: rgb(23, 23, 23);
  font-family: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  // :first-child {
  //   margin-top: 0;
  // }
  // p :hover{
  //   border: 1px solid blue;
  // }
  .mention {
    background-color: #a9d5ff;
    border-radius: 4px;
    box-decoration-break: clone;
    color: #1873cc;
    padding: 1px 5px;
  }
  img {
    // display: block;
    height: auto;
    // margin: 1.5em 0;
    max-width: 100%;
    width: 100px;

    &.ProseMirror-selectednode {
      outline: 3px solid #0064ff;
    }
  }
  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;
    p {
      margin: 5px 0;
    }
    li {
      align-items: flex-start;
      display: flex;
      align-items: center;

      > label {
        margin-right: 0.5em;
        user-select: none;
        width: 20px;
        input {
          // background: red;
        }
      }

      > div {
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -0.5px;
    line-height: normal;
    padding: 5px 3px;
    position: absolute;
    top: -2em;
    user-select: none;
    white-space: nowrap;
  }
  ul {
    padding-left: 25px;
  }
  ul li::marker {
    color: #0064ff;
  }
  ol {
    padding-left: 25px;
  }
  ol li::marker {
    color: #0064ff;
  }
  a {
    color: #0064ff;
    cursor: pointer;
    position: relative;

    &:hover {
      border-bottom: 2px solid #0064ff;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #3d251414;
    border-top: 2px dashed #cccccc;
    cursor: pointer;
    margin: 10px 0;

    &.ProseMirror-selectednode {
      border-top: 1px solid #e3eeff;
    }
  }
  mark {
    // background-color: #faf594;
    border-radius: 0.4em;
    box-decoration-break: clone;
    padding: 0.1em 0.3em;
  }
  // 引用样式
  blockquote {
    margin: 0;
    border-left: 3px solid #e5e7eb;
    // margin: 1.5rem 0;
    padding-left: 10px;
    color: rgb(135, 136, 138);
    p {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }
  }
  pre {
    background: black;
    border-radius: 5px;
    font-family: "Consolas, Monaco", "Andale Mono", "Ubuntu Mono", monospace;
    margin: 10px 0;
    color: white;
    padding: 20px;
    padding-top: 40px;
    font-size: 14px;

    code {
      background: none;
      color: inherit;
      // font-size: 0.8rem;
      padding: 0;
    }
    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid #dfe1e5;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin: 0 !important;
      }
    }

    th {
      // background-color: gray;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgb(160 198 255 / 60%);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #0064ff;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  mark {
    border-radius: 0;
    padding: 0;
  }

  .tableWrapper {
    margin: 1.5em 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
[contenteditable] {
  outline: 1px solid transparent;
  border: 1px solid #fff;
  width: 100%;
}

[contenteditable]:focus {
  border: 1px solid #dddddd0f;

  border-radius: 3px;
}
#LeftMenu {
  // transform: translateX(-50px);
}
.el-color-dropdown {
  z-index: 99999 !important;
}
.my-custom-class-link ::after {
  content: "attr(data-tooltip)"; /* 读取 data-tooltip 作为内容 */
  // visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 6px;
  border-radius: 5px;

  /* 绝对定位 */
  position: absolute;
  bottom: 125%; /* 位于元素上方 */
  left: 50%;
  transform: translateX(-50%);

  /* 透明度 & 过渡 */
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none; /* 防止鼠标干扰 */
}
.my-custom-class-link:hover::after {
  visibility: visible;
  opacity: 1;
}
</style>
